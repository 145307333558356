import React from "react";

export const InputBox = ({
  label,
  icon,
  children,
  error,
  nameOfClass,
  nameOfClass2,
}) => {
  return (
    <div className={`InputBox ${nameOfClass}`}>
      {label && <label className="mb-1 ff_md">{label}</label>}
      <div
        className={`d-flex align-items-center position-relative h-100 ${nameOfClass2}`}
        id="container"
      >
        {children}
      </div>
      {error && <span className="text-danger">{error}</span>}
    </div>
  );
};

export const AdminInput = ({
  nameOfClass,
  responsiveClassLabel,
  Heading,
  labelDesc,
  label,
  nameOfInput,
  children,
  notes,
  error,
}) => {
  return (
    <div className={`form-input col-sm-6 ${nameOfClass}`}>
      {Heading && (
        <h4 htmlFor={labelDesc} className="PageHeading">
          {Heading}
        </h4>
      )}
      <div
        className={`${responsiveClassLabel} ff_rg d-flex justify-content-between`}
      >
        {label && (
          <label htmlFor={label} className="form-label text-capitalize">
            {label}
          </label>
        )}

        {labelDesc && (
          <label htmlFor={labelDesc} className="form-label flex-end">
            {labelDesc}
          </label>
        )}
      </div>
      <div className={`form-input-type ${nameOfInput}`}>{children}</div>
      {notes !== undefined && <div className={`notes f12`}>{notes}</div>}
      {error !== undefined && (
        <div className={`error-message mt-1`}>{error}&nbsp;</div>
      )}
    </div>
  );
};

export const AdminInputBoxLarge = ({
  label,
  children,
  nameOfClass,
  taskTitle,
  setTaskTitle,
  placeHolderText,
}) => {
  return (
    <div className={`d-flex flex-column ${nameOfClass}`}>
      <label className="ff ff_md f14 mb-1">{label}</label>
      {/* <input
        type="text"
        value={taskTitle}
        onChange={(e) => {
          setTaskTitle(e.target.value);
        }}
        className="txt-primary adminInputBoxLarge"
        placeholder="Task Title"
      /> */}
      <textarea
        typeof="text"
        className="txt-primary adminInputBoxLarge ff ff_rg f14"
        value={taskTitle}
        onChange={(e) => {
          let inputValue = e.target.value;

          if (inputValue.length > 0) {
            inputValue =
              inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
          }

          setTaskTitle(inputValue);
        }}
        placeholder={placeHolderText}
      ></textarea>
    </div>
  );
};
