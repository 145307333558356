import { message } from "antd";
import axios from "../../helpers/axios";
import { ROLE, API_URL } from "../../utils";
import {
  dispatchLoading,
  dispatchToast,
  dispatchError,
  elseHandler,
  catchHandler,
} from "../../utils";
const BACKEND_API_URL = process.env.REACT_APP_API_URL;
export const LoginAPI = (data, navigate, redirectURL) => async (dispatch) => {
  dispatchLoading(dispatch, "auth", true);
  axios
    .post(`${BACKEND_API_URL}/auth/login`, data)
    .then((result) => {
      if (result?.data?.user) {
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: result?.data?.data,
        });
        dispatchLoading(dispatch, "auth", false);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("token", result?.data?.token);
        let userRole = result?.data?.user?.role;
        // localStorage.setItem("user", result?.data?.user);
        localStorage.setItem("user", JSON.stringify(result?.data?.user));
        if (userRole === ROLE?.ADMIN) {
          dispatch({ type: "SET_USER_ROLE", payload: ROLE.ADMIN });
          // navigate("/dashboard");
          dispatchToast(dispatch, "success", "Login Successful");
          dispatchError(dispatch, "auth", undefined);
        } else if (userRole === ROLE?.EMPLOYEE) {
          dispatch({ type: "SET_USER_ROLE", payload: ROLE.EMPLOYEE });
          // navigate("/dashboard");
          dispatchToast(dispatch, "success", "Login Successful");
          dispatchError(dispatch, "auth", undefined);
        } else if (userRole === ROLE?.PROJECTMANAGER) {
          dispatch({ type: "SET_USER_ROLE", payload: ROLE.PROJECTMANAGER });
          // navigate("/dashboard");
          dispatchToast(dispatch, "success", "Login Successful");
          dispatchError(dispatch, "auth", undefined);
        } else if (userRole === ROLE?.CLIENT) {
          dispatch({ type: "SET_USER_ROLE", payload: ROLE.CLIENT });
          // navigate("/dashboard");
          dispatchToast(dispatch, "success", "Login Successful");
          dispatchError(dispatch, "auth", undefined);
        }
        if (redirectURL) {
          navigate(redirectURL);
        } else {
          navigate("/dashboard");
        }
      } else {
        elseHandler(dispatch, "auth", result?.data);
        dispatchLoading(dispatch, "auth", false);
      }
    })
    .catch((error) => {
      console.log("Error:", error);
      // dispatchToast(dispatch, "error", "Invalid credentials");
      dispatchLoading(dispatch, "auth", false);
      message.error(error.response.data.message)
      //   catchHandler(dispatch, "auth");
    });
};

export const forgotPasswordAPI = (data, navigate) => async (dispatch) => {
  dispatchLoading(dispatch, "auth", true);
  axios
    .post(`${BACKEND_API_URL}/auth/forgot-password`, data)
    .then((response) => {
      message.info("Email sent");
      navigate("/");
    })
    .catch((e) => {
      console.log("Error: ", e);
      message.error(e.response.data.message);
    })
    .finally(() => {
      dispatchLoading(dispatch, "auth", false);
    });
};

export const resetPasswordAPI = (data, navigate) => async (dispatch) => {
  dispatchLoading(dispatch, "auth", true);
  axios
    .post(`${BACKEND_API_URL}/auth/reset-password`, data)
    .then((response) => {
      message.info("Password reset successfull");
      navigate("/");
    })
    .catch((e) => {
      message.error(e);
    })
    .finally(() => {
      dispatchLoading(dispatch, "auth", false);
    });
};
