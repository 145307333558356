import React, {useEffect, useState} from "react";
import {Dialog} from "@capacitor/dialog";
import {ShareExtension} from 'capacitor-share-extension';
import {App} from '@capacitor/app';
import {Modal} from "antd";
import {InputBox} from "../Auth/components/Inputbox";
import {Capacitor} from "@capacitor/core";


const IntentHandler = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [url, setUrl] = useState('');
    const [videoUrl, setVideoUrl] = useState('');

    const findType = (str) => {
        const match = str.match(/\?(.*?)=/);

        if (match && match[1]) {
            return match[1];
        } else {
            return "no_type"
        }
    }
    useEffect(() => {
        const checkIntent = () => {
            ShareExtension.checkSendIntentReceived().then(async data => {
                console.log('Shared data:', data);
                console.log('Shared data:', JSON.stringify(data));
                console.log('Shared data:', data.payload?.[0].url);
                console.log('Shared data:', data.payload?.[0].title);
                await showAlert(data);
                setIsOpen(true);
                setUrl(data.payload?.[0].webPath ?? "");
                setVideoUrl(data.payload?.[0].webPath ?? "");
            });
        }
        const showAlert = async (json) => {
            if (json.payload) {
                await Dialog.alert({
                    title: json.payload?.[0].title,
                    message: json.payload?.[0].url + "\ntotal files:" + json.payload?.length,
                });
            }
        };
        App.addListener('resume', () => {
            if (Capacitor.getPlatform() === "android") {
                checkIntent();
            }
        });
        checkIntent();
    }, []);

    useEffect(() => {

        const initIOS = () => {

            App.addListener('appUrlOpen', async (data) => {
                // Extract the shared data from the URL
                const sharedUrl = new URL(data.url);
                console.log('Shared data:', data);

                if (sharedUrl.toString()) {
                    console.log("Shared raw URL: ", sharedUrl.toString());

                    const dataType = findType(sharedUrl.toString());
                    console.log("Type", dataType);
                    switch (dataType) {

                        case "image": {
                            const convertedFilepath = Capacitor.convertFileSrc(sharedUrl.toString().split('pms://share?image=').pop())
                            console.log('convertedImagePath', convertedFilepath);
                            setUrl(convertedFilepath);
                            break;
                        }
                        case "video": {
                            const convertedFilepath = Capacitor.convertFileSrc(sharedUrl.toString().split('pms://share?video=').pop())
                            console.log('convertedVideoPath', convertedFilepath);
                            setUrl(convertedFilepath);
                            break;
                        }
                        case "text": {
                            const convertedFilepath = Capacitor.convertFileSrc(sharedUrl.toString().split('pms://share?text=').pop())
                            console.log('convertedTextPath', convertedFilepath);
                            setUrl(convertedFilepath);
                            break;
                        }
                        case "url": {
                            const convertedFilepath = Capacitor.convertFileSrc(sharedUrl.toString().split('pms://share?url=').pop())
                            console.log('convertedUrlPath', convertedFilepath);
                            setUrl(convertedFilepath);
                            break;
                        }
                        default: {
                            console.log('file type default');
                        }

                    }

                    setIsOpen(true);
                }
            });
        }
        initIOS();
        console.log('init called');
    }, [])

    return (
        <>    <Modal
            open={false}
            onOk={() => {
            }}
            onCancel={() => {
                setIsOpen(false);
            }}
            footer={null}
            maskClosable={false}
            closable={true}
            centered={true}
            width={'31.25rem'}
            className="modelMaxHeight"
        >
            <h3 className="text-center mt-4 mb-3 txt-primary ff ff_bd f24 responsiveModalLabel">{`Add`}</h3>
            <img src={url} style={{width: '100%', height: 'auto'}} alt={'no image'}/>
            <video src={videoUrl} width="100%" height="auto" preload="auto" playsInline controls></video>
            {/*<video src={videoUrl} style={{width: '100%', height: 100}}/>*/}
            <div className="d-flex flex-column justify-content-center">
                <div className="mb-3">
                </div>

                <div className="mb-3">
                    <InputBox label={`Email`}>
                        <input
                            type="email"
                            value={url}
                            onChange={(e) => {
                            }}
                            className="FormInput form-control"
                            autoComplete="off"
                            placeholder="Enter Email"
                        />
                    </InputBox>
                </div>
                <div className="d-flex justify-content-center mt-3 mb-4" onClick={() => {
                    setIsOpen(false);
                }}>
                    <button className="btn btn-primary px-5">
                        {'Add'}
                    </button>
                </div>
            </div>
        </Modal></>
    );
};
export default IntentHandler;
