// src/context/SocketContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

// Define the URL of your socket server
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL;
const API_KEY = process.env.REACT_APP_API_SECRET_KEY;
const SOCKET_SERVER_PATH = process.env.REACT_APP_SOCKET_PATH;

// Create a context for the socket
const SocketContext = createContext(null);

// Create a provider component
export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize the socket connection
    const newSocket = io.connect(SOCKET_SERVER_URL, {
      hostname: SOCKET_SERVER_URL,
      path: SOCKET_SERVER_PATH,
      query: {
        id: API_KEY,
      },
      transports: ['websocket'],
      secure: true,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
    });

    // Add error handling
    newSocket.on('connect_error', (err) => {
      console.error('Socket connection error:', err.message);
    });

    newSocket.on('error', (err) => {
      console.error('Socket error:', err.message);
    });
    // const newSocket = io(SOCKET_SERVER_URL);
    setSocket(newSocket);

    // Clean up the socket connection on component unmount
    return () => newSocket.close();
  }, []);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

// Custom hook to use the socket context
export const useSocket = () => {
  const context = useContext(SocketContext);
  if (context === null) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};
