import React, { useEffect, useState } from "react";
import { InputBox } from "../components/Inputbox";
import logo from "../../Assets/logo.webp";
import { useNavigate, useParams } from "react-router";
import { startTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginAPI, forgotPasswordAPI } from "../../Admin/Redux/auth";
import BtnLoader from "../../Components/BtnLoader";

import "../../Auth/css/loginBootstrap.min.css";

function ForgotPassword() {
  const user = JSON.parse(localStorage.getItem("user"));
  const { redirectURL } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const { authLoading } = useSelector((state) => state.loading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, []);

  const SubmitHandler = (e) => {
    startTransition(() => {
      e.preventDefault();

      const validationErrors = {};
      if (!email.trim()) {
        validationErrors.email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        validationErrors.email = "Invalid email format";
      }

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        dispatch(forgotPasswordAPI({ email }, navigate));
        // setEmail("");
        // setPassword("");
        setErrors({});
      }
    });
  };

  return (
    <div className="Background d-flex flex-column align-items-center justify-content-center">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="px-3 py-2">
          <img src={logo} alt="Logo" width={200} height={91} className="img-fluid" loading="lazy"/>
        </div>
        <div className="d-flex">
          <form
            className="SignInForm rounded-2 center mt-1 mt-sm-5 mb-5"
            autoComplete="off"
          >
              <p className="FormHeaderText ff_bd f30 mt-3 mb-2">
                Forgot Password
              </p>
            <div className="ff_md f16 mx-4 my-4">
              <InputBox label="Email Address">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  className="form-control"
                  autoComplete="off"
                  placeholder="Enter Email"
                />
              </InputBox>
              {errors.email && (
                <span className="text-danger">{errors.email}</span>
              )}
            </div>
            <div className="d-flex justify-content-center my-4">
              <button className="btn btn-primary px-4" onClick={SubmitHandler}>
                {authLoading ? <BtnLoader height={"24px"} /> : "Send Email"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
