import React from "react";
import { ReactComponent as UnauthorizedSVG } from "../../Assets/Unauthorized.svg";

const Unauthorized = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <UnauthorizedSVG />
    </div>
  );
};

export default Unauthorized;
