import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const isAuthenticated = () => {
  return localStorage.getItem("isLoggedIn");
};

const ProtectedRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localStorage.getItem("isLoggedIn")) {
      dispatch({ type: "SET_REDIRECT_URL", payload: location.pathname });
    }
  }, [location.pathname]);
  return isAuthenticated() ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
