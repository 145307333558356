import React, { useEffect, useState } from "react";
import { InputBox } from "../components/Inputbox";
import logo from "../../Assets/logo.webp";
import { useNavigate, useParams } from "react-router";
import { startTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginAPI,
  forgotPasswordAPI,
  resetPasswordAPI,
} from "../../Admin/Redux/auth";
import BtnLoader from "../../Components/BtnLoader";
import Icons from "../../Assets/Icons";

import "../../Auth/css/loginBootstrap.min.css";

function ResetPassword() {
  const { token } = useParams();
  const user = JSON.parse(localStorage.getItem("user"));
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [errors, setErrors] = useState({});
  const { authLoading } = useSelector((state) => state.loading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Token: ", token);
  }, []);

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, []);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };

  const SubmitHandler = (e) => {
    startTransition(() => {
      e.preventDefault();

      const validationErrors = {};
      if (!password.trim()) {
        validationErrors.password = "Password is required";
      }
      if (!confirmPassword.trim()) {
        validationErrors.confirmPassword = "Confirm Password is required";
      }

      if (password.trim() !== confirmPassword.trim()) {
        validationErrors.matchingPassword = "Passwords should match";
      }

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        const dataToSend = {
          resetToken: token,
          newPassword: password,
        };
        dispatch(resetPasswordAPI(dataToSend, navigate));
        // setEmail("");
        // setPassword("");
        setErrors({});
      }
    });
  };

  return (
    <div className="Background d-flex flex-column align-items-center justify-content-center">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="px-3 py-2">
          <img src={logo} alt="Logo" width={200} height={91} className="img-fluid" loading="lazy"/>
        </div>
        <div className="d-flex">
          <form
            className="SignInForm rounded-2 center mt-1 mt-sm-5 mb-5"
            autoComplete="off"
          >
            <p className="FormHeaderText ff_bd f30 mt-3 mb-2">Reset Password</p>
            <div className="ff_md f16 mx-4 position-relative">
              <InputBox label="Password">
                <input
                  type={passwordType}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  className="form-control pe-4"
                  placeholder="Password"
                  autoComplete="off"
                />
                <div
                  id="iconContainer"
                  className={`${
                    passwordType === "password" ? "opacity-50" : "opacity-100"
                  } mx-2 cursor-pointer position-absolute z-1 end-0`}
                  onClick={togglePassword}
                >
                  {Icons.eye}
                </div>
              </InputBox>
              {errors.password && (
                <span className="text-danger">{errors.password}</span>
              )}
            </div>
            <div className="ff_md f16 mx-4 position-relative">
              <InputBox label="Confirm Password">
                <input
                  type={confirmPasswordType}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  className="form-control pe-4"
                  placeholder="Confirm Password"
                  autoComplete="off"
                />
                <div
                  id="iconContainer"
                  className={`${
                    confirmPasswordType === "password"
                      ? "opacity-50"
                      : "opacity-100"
                  } mx-2 cursor-pointer position-absolute z-1 end-0`}
                  onClick={toggleConfirmPassword}
                >
                  {Icons.eye}
                </div>
              </InputBox>
              {errors.confirmPassword && (
                <span className="text-danger">{errors.confirmPassword}</span>
              )}
              {errors.matchingPassword && (
                <span className="text-danger">{errors.matchingPassword}</span>
              )}
            </div>
            <div className="d-flex justify-content-center my-4">
              <button className="btn btn-primary px-4" onClick={SubmitHandler}>
                {authLoading ? <BtnLoader height={"24px"} /> : "Reset"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
