import axios from "axios";
import * as utils from "../utils";
// import { loadLanguages } from "i18next";

const API_URL = process.env.REACT_APP_API_URL;

// axios interceptor request to setup token in header
axios.interceptors.request.use(async (config) => {
  config.baseURL = API_URL;
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers = {
    ...config.headers,
    Accept: "application/json",
    "x-api-key": process.env.REACT_APP_API_SECRET_KEY,
  };
  return config;
});

//axios interceptor response to set auto logout
// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.message === "Network Error") {
//       error.response = {
//         status: 500,
//         data: {
//           message: "Network Error | Network Unavailable",
//         },
//       };
//     }
//     if (error.response.status === 401) {
//       error.response = {
//         status: 401,
//         data: {
//           message: "Unauthorized",
//         },
//       };
//       utils.Reset();
//     }
//     if (error.response.status === 500) {
//       if (!error.response.data || !error.response.data.message) {
//         error.response = {
//           status: 500,
//           data: {
//             message: "Something went wrong",
//           },
//         };
//       }
//     }
//     return Promise.reject(error);
//   }
// );

export default axios;
